<template>
    <div class="root_info_page">
        <div class="container ner_responesize" style="padding: 0;">
            <div class="info_page" v-if="token !== null">
                <div class="wpinfo_user">
                    <div class="wpInfo_fixed">
                        <h2>Thông tin</h2>
                        <div class="input_info">
                            <label>Họ tên</label>
                            <input type="text" v-model="fullname">
                            <img src="../assets/images/us.png" alt="" class="iconInput">
                            <button @click="changeInfo()" class="btnChangeInfo">Cập nhật</button>
                        </div>
                        <div class="input_info">
                            <label>Email</label>
                            <input type="text" v-model="email">
                            <img src="../assets/images/em.png" alt="" class="iconInput">
                            <button @click="changeInfo()" class="btnChangeInfo">Cập nhật</button>
                        </div>
                        <div class="input_info">
                            <label>Số điện thoại</label>
                            <input type="text" v-model="phone">
                            <img src="../assets/images/phone.png" alt="" class="iconInput">
                            <button @click="changeInfo()" class="btnChangeInfo">Cập nhật</button>
                        </div>
                        <div class="input_info">
                            <label>Ngày đăng ký</label>
                            <input type="text" :value="created_date">
                            <img src="../assets/images/date.png" alt="" class="iconInput">
                        </div>
                        <div class="input_info">
                            <label>Tình trạng VIP</label>
                            <input type="text" value="1 ngày" v-if="type_cash == 1">
                            <input type="text" value="3 ngày" v-else-if="type_cash == 2">
                            <input type="text" value="1 tháng" v-else-if="type_cash == 3">
                            <input type="text" value="Vĩnh viễn" v-else>
                            <img src="../assets/images/tt.png" alt="" class="iconInput">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
export default {
    name: 'Header_tool',
    data () {
        return {
            token: window.localStorage.getItem('token'),
            fullname: '',
            phone: '',
            email: '',
            created_date: '',
            type_cash: ''
        }
    },
    components: {
    },
    created () {
        if(this.token !== null){
            this.getListInfo()
        }
    },
    methods: {
        logout (e) {
            e.preventDefault()
            $('.loading_show').show().fadeIn(10)
            this.axios.post(this.api_logout).then(res => {
                if (res.data.success === true) {
                    $('#loading_show').css('display', 'none')
                    localStorage.clear();
                    this.$swal({
                        position: 'top-end',
                        icon: 'success',
                        title: res.data.message,
                        showConfirmButton: false,
                        timer: 4000
                    })
                    window.location.href = '/'
                }
            })
        },
        getListInfo () {
            this.axios.get(this.api_infouser, {
                headers: {
                    Authorization: 'Bearer ' + this.token
                }
            }).then((response) => {
                this.fullname = response.data.data.user.fullname
                this.email = response.data.data.user.email
                this.phone = response.data.data.user.phone
                this.created_date = response.data.data.user.created_date
                this.type_cash = response.data.data.user.type_cash
            }).catch(e => {
                this.orther = e.response
                localStorage.clear();
                setTimeout(function() {
                    this.axios.post(this.api_logout).then(res => {
                        this.resNull = res
                        localStorage.clear();
                    })
                }.bind(this), 5000);
                setTimeout(function() {
                    window.location.href = '/'
                }, 2000);
            })
        },
        changeInfo () {
            $('.loading_show').show().fadeIn(10)
            this.axios.post(this.api_changeInfo, 
                {
                    name: this.fullname,
                    email: this.email,
                    phone: this.phone,
                },
                { headers: {
                    Authorization: 'Bearer ' + this.token
                }},
            ).then(res => {
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 3000
                })
                // this.getListInfo()
            }).catch(e => {
                this.$swal({
                    position: 'top-end',
                    icon: 'error',
                    title: e.response.data.message,
                    showConfirmButton: false,
                    timer: 3000
                })
                $('#loading_show').css('display', 'none')
            })
        },
    }
}
</script>
<style></style>
