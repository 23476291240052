<template>
    <div class="root_register">
        <form action="" method="post" id="frm_register" @submit="register">
            <div class="row">
                <div class="col-md-6 col-sm-6 col-xs-12 left_frm_register">
                    <div class="wrapper_left_register">
                        <div class="input_dk">
                            <label>Tên đăng nhập</label>
                            <input type="text" v-model="username" placeholder="Tên đăng nhập">
                            <img src="../assets/images/us.png" alt="" class="iconInput">
                        </div>
                        <div class="input_dk">
                            <label>Mật khẩu thành viên</label>
                            <input type="password" v-model="password" placeholder="Mật khẩu thành viên">
                            <img src="../assets/images/pw.png" alt="" class="iconInput">
                        </div>
                        <div class="input_dk">
                            <label>Xác nhận mật khẩu</label>
                            <input type="password" v-model="password_confirm" placeholder="Xác nhận mật khẩu">
                            <img src="../assets/images/pw.png" alt="" class="iconInput">
                        </div>
                        <div class="input_dk">
                            <label>Họ tên</label>
                            <input type="text" v-model="name" placeholder="Họ tên">
                            <img src="../assets/images/us.png" alt="" class="iconInput">
                        </div>
                        <div class="input_dk">
                            <label>Địa chỉ email</label>
                            <input type="email" v-model="email" placeholder="Địa chỉ email">
                            <img src="../assets/images/em.png" alt="" class="iconInput">
                        </div>
                        <div class="input_dk">
                            <label>Số điện thoại</label>
                            <input type="text" v-model="phone" placeholder="Số điện thoại">
                            <img src="../assets/images/phone.png" alt="" class="iconInput">
                        </div>
                    </div>
                </div>
            </div>
            <div class="btn_dangky">
                <button type="submit"><img src="../assets/images/dk.png" alt="" class=""></button>
            </div>
            <p>Bạn đã có tài khoản? <span @click="handleClick" class="register_text"><strong>Đăng nhập</strong></span></p>
        </form>
    </div>
</template>

<script>
import $ from 'jquery'
export default {
    name: 'Register_tool',
    data () {
        return {
            name: '',
            email: '',
            phone: '',
            username: '',
            password: '',
            password_confirm: '',
            codeParam: '',
        }
    },
    components: {
    },
    created () {
    },
    methods: {
        register (e) {
            e.preventDefault()
            $('.loading_show').show().fadeIn(10)
            this.axios.post(this.api_register, {
                name: this.name,
                email: this.email,
                phone: this.phone,
                username: this.username,
                password: this.password,
                password_confirm: this.password_confirm,
                referrer: this.codeParam
            }).then(res => {
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 4000
                })
                this.$emit('showMd_noti')
                this.name = this.username = this.email = this.password = this.password_confirm = this.phone = ''
                // window.location.reload()
            }).catch(e => {
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'error',
                    title: e.response.data.message,
                    showConfirmButton: false,
                    timer: 3000
                })
            })
        },
        handleClick () {
            this.$emit('showMd')
        }
    }
}
</script>
<style>
</style>
