<template>
    <div class="wrapper_home mg_top">
        <div class="container ner_responesize">
            <div class="wrapper_game_chicken">
                <div class="game_propose" v-if="token != null">
                    <h2 class="title_game"><span>Được đề xuất</span></h2>
                    <div class="row">
                        <div class="col-md-4 col-sm-4 col-xs-4 item_game_propose" v-for="(item, index) in list90" :key="index">
                            <div class="img_pecent_game">
                                <a href="javascript:;" @click="showLogin" v-if="token == null">
                                    <img :src="item.img" alt="" class="img_game_pro">
                                    <img src="../assets/images/0.gif" alt="" class="img_pecent">
                                </a>
                                <a href="javascript:;" @click="showCompClick($event)" :data_percent="item.pecent" :data_img="item.img" v-else-if="statusTime > 0">
                                    <img :src="item.img" alt="" class="img_game_pro" :data_percent="item.pecent" :data_img="item.img">
                                    <img :src="item.imgpecent" alt="" class="img_pecent" :data_percent="item.pecent" :data_img="item.img">
                                </a>
                                <a href="javascript:;" v-else>
                                    <img :src="item.img" alt="" class="img_game_pro">
                                    <img src="../assets/images/0.gif" alt="" class="img_pecent">
                                </a>
                                <div class="wp_processBar">
                                    <div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="listgame_page">
                    <h2 class="title_game"><span>Trò chơi khác</span></h2>
                    <div class="content_tab_game">
                        <div class="wp_item_game">
                            <div class="row">
                                <div class="col-md-4 col-sm-4 col-xs-4 item_game_propose" v-for="(item, index) in listGameshootFish" :key="index">
                                    <div class="img_pecent_game">
                                        <a href="javascript:;" @click="showLogin" v-if="token == null">
                                            <img :src="item.img" alt="" class="img_game_pro">
                                            <img src="../assets/images/0.gif" alt="" class="img_pecent">
                                        </a>
                                        <a href="javascript:;" @click="showCompClick($event)" :data_percent="item.pecent" :data_img="item.img" v-else-if="statusTime > 0">
                                            <img :src="item.img" alt="" class="img_game_pro" :data_percent="item.pecent" :data_img="item.img">
                                            <img :src="item.imgpecent" alt="" class="img_pecent" :data_percent="item.pecent" :data_img="item.img">
                                        </a>
                                        <a href="javascript:;" v-else>
                                            <img :src="item.img" alt="" class="img_game_pro">
                                            <img src="../assets/images/0.gif" alt="" class="img_pecent">
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- modal login -->
        <div id="modal-example" class="modal-login">
            <div class="overlay" v-if="showModal" @click="showModal = false"></div>
            <div class="modal" v-if="showModal">
                <div class="wp-modal-login">
                    <div class="header-modal">
                        <button class="close-modal_login" @click="showModal = false"><img src="../assets/images/close_md.png" alt=""></button>
                    </div>
                    <div class="content-modal_tab">
                        <div>
                            <div class="logo_modal_login">
                                <div class="img_pathner_md">
                                    <img src="../assets/images/logo.png" alt="">
                                </div>
                            </div>
                            <Login_tool @showMd="showModalrg" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end modal login -->
        <!-- modal register -->
        <div id="modal-example" class="modal-login">
            <div class="overlay" v-if="showModal_dk" @click="showModal_dk = false"></div>
            <div class="modal" v-if="showModal_dk">
                <div class="wp-modal-register">
                    <div class="header-modal">
                        <button class="close-modal_login" @click="showModal_dk = false"><img src="../assets/images/close_md.png" alt=""></button>
                    </div>
                    <div class="content-modal_tab">
                        <div>
                            <div class="logo_modal_login">
                                <div class="img_pathner_md">
                                    <img src="../assets/images/logo.png" alt="">
                                </div>
                            </div>
                            <div class="form_dangky">
                                <Register_tool @showMd="showModallg" @showMd_noti="showModal_noti"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end modal register -->
        <!-- modal noti register -->
        <div id="modal-example" class="modal-login modal-cash">
            <div class="overlay" v-if="showModal_notiDk" @click="showModal_notiDk = false"></div>
            <div class="modal" v-if="showModal_notiDk">
                <div class="wp-modal-login">
                    <div class="header-modal">
                        <button class="close-modal_login" @click="showModal_notiDk = false"><img src="../assets/images/close_md.png" alt=""></button>
                    </div>
                    <div class="content-modal_tab">
                        <div>
                            <div class="logo_modal_login">
                                <div class="img_premium">
                                    <img src="../assets/images/tiger.png" alt="">
                                </div>
                            </div>
                            <div class="form_dangky">
                                <h2 class="noti_register">BẠN ĐÃ ĐĂNG KÝ THÀNH CÔNG, VUI LÒNG CHỜ CHÚNG TÔI XÁC MiNH THÔNG TIN KHI KHÁCH ĐĂNG KÝ THÀNH CÔNG GÓI VIP</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end modal noti register -->
        <!-- modal cash -->
        <div id="modal-example" class="modal-login modal-cash">
            <div class="overlay" v-if="showModal_cash" @click="showModal_cash = false"></div>
            <div class="modal" v-if="showModal_cash">
                <div class="wp-modal-login">
                    <div class="header-modal" v-if="checkShmd == 1">
                        <button class="close-modal_login" @click="hideModalCash"><img src="../assets/images/close_md.png" alt=""></button>
                    </div>
                    <div class="content-modal_tab">
                        <div>
                            <div class="logo_modal_login">
                                <div class="img_premium">
                                    <img src="../assets/images/premium.gif" alt="">
                                </div>
                            </div>
                            <div class="form_dangky">
                                <ul class="img_listcash">
                                    <li @click="creditCash(1)"><p><img src="../assets/images/1n.png" alt=""></p></li>
                                    <li @click="creditCash(2)"><p><img src="../assets/images/3n.png" alt=""></p></li>
                                    <li @click="creditCash(3)"><p><img src="../assets/images/1t.png" alt=""></p></li>
                                    <li @click="creditCash(4)"><p><img src="../assets/images/vv.png" alt=""></p></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end modal cash -->
        <!-- modal noti register -->
        <div id="modal-example" class="modal-login modal-cash">
            <div class="overlay" v-if="showModal_noticash" @click="showModal_noticash = false"></div>
            <div class="modal" v-if="showModal_noticash">
                <div class="wp-modal-login">
                    <div class="header-modal">
                        <button class="close-modal_login" @click="showModal_noticash = false"><img src="../assets/images/close_md.png" alt=""></button>
                    </div>
                    <div class="content-modal_tab">
                        <div>
                            <div class="logo_modal_login">
                                <div class="img_premium">
                                    <img src="../assets/images/tiger.png" alt="">
                                </div>
                            </div>
                            <div class="form_dangky">
                                <h2 class="noti_register">BẠN ĐÃ NẠP TIỀN THÀNH CÔNG, VUI LÒNG CHỜ CHÚNG TÔI XÁC MiNH THÔNG TIN</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end modal noti register -->
        <!-- end modal comp -->
        <div id="modal-example" class="modal-login modal-cash">
            <div class="overlay" v-if="showModal_comp" @click="showModal_comp = false"></div>
            <div class="modal" v-if="showModal_comp">
                <div class="wp-modal-login">
                    <div class="header-modal">
                        <button class="close-modal_login" @click="showModal_comp = false"><img src="../assets/images/close_md.png" alt=""></button>
                    </div>
                    <div class="content-modal_tab">
                        <div class="wp_img_compa">
                            <img src="../assets/images/comp.gif" alt="" class="img_comp">
                            <span>{{data_percent}}%</span>
                        </div>
                        <div class="img_game_pp">
                            <img :src="imggame" alt="" class="imggame_popup">
                            <img src="../assets/images/10.gif" alt="" class="img_pecent_pp" v-if="data_percent == 10">
                            <img src="../assets/images/20.gif" alt="" class="img_pecent_pp" v-else-if="data_percent == 20">
                            <img src="../assets/images/30.gif" alt="" class="img_pecent_pp" v-else-if="data_percent == 30">
                            <img src="../assets/images/40.gif" alt="" class="img_pecent_pp" v-else-if="data_percent == 40">
                            <img src="../assets/images/50.gif" alt="" class="img_pecent_pp" v-else-if="data_percent == 50">
                            <img src="../assets/images/60.gif" alt="" class="img_pecent_pp" v-else-if="data_percent == 60">
                            <img src="../assets/images/70.gif" alt="" class="img_pecent_pp" v-else-if="data_percent == 70">
                            <img src="../assets/images/80.gif" alt="" class="img_pecent_pp" v-else-if="data_percent == 80">
                            <img src="../assets/images/90.gif" alt="" class="img_pecent_pp" v-else>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end modal comp -->
        <div v-if="token != null" class="parent" @click="showModalCash">
            <Vue3DraggableResizable
                :initW="114"
                :initH="115"
                v-model:x="x"
                v-model:y="y"
                v-model:w="w"
                v-model:h="h"
                v-model:active="active"
                :draggable="true"
                :resizable="false"
            >
            </Vue3DraggableResizable>
        </div>
    </div>
</template>

<script>
import Login_tool from '@/components/Login'
import Register_tool from '@/components/Register'
import $ from 'jquery'

export default {
    name: 'ShootFish_tool',
    data () {
        return {
            showModal: false,
            showModal_dk: false,
            token: window.localStorage.getItem('token'),
            idUser: window.localStorage.getItem('numberus'),
            statusTime: window.localStorage.getItem('status_time'),
            listGameshootFish: [], 
            list90: [],
            showModal_notiDk: false,
            showModal_cash: false,
            showModal_noticash: false,
            orther: [],
            checkShmd: 0,
            showModal_comp: false,
            data_percent: '',
            imggame: '',
        }
    },
    components: {
        Login_tool,
        Register_tool,
    },
    mounted () {
        let status_time = window.localStorage.getItem('status_time')
        if(status_time == 0){
            this.showModal_cash = true
        }
    },
    created () {
        this.GetListGameshootFish()
        if(this.token != null){
            this.checkExp()
            setInterval(this.checkExp, 30000);
        }
    },
    computed: {
        
    },
    methods: {
        showLogin () {
            this.showModal = true
        },
        showModal_noti () {
            this.showModal_notiDk = true
            this.showModal_dk = false
        },
        showModalrg () {
            this.showModal = false
            this.showModal_dk = true
        },
        showModallg () {
            this.showModal = true
            this.showModal_dk = false
        },
        showModalCash () {
            this.showModal_cash = true
            this.checkShmd = 1
        },
        hideModalCash () {
            this.showModal_cash = false
            this.checkShmd = 0
        },
        shuffle(arra1) {
            var ctr = arra1.length, temp, index;
            while (ctr > 0) {
                index = Math.floor(Math.random() * ctr);
                ctr--;
                temp = arra1[ctr];
                arra1[ctr] = arra1[index];
                arra1[index] = temp;
            }
            return arra1;
        },
        GetListGameshootFish () {
            const arrayRandom = [10, 20, 30, 40, 50, 60, 70, 80, 90, 90, 90, 30, 40, 70]
            arrayRandom.sort(function() {
                return 0.5 - Math.random();
            });
            let arr = []
            for (let i = 0; i < arrayRandom.length; i++) {
                arr[i] = { name: '', img: require('@/assets/images/fish/'+ Number(i + 1) + '.png'), imgpecent: require('@/assets/images/'+ arrayRandom[i] + '.gif'), pecent: arrayRandom[i] }
            }
            this.listGameshootFish = this.shuffle(arr)
            this.list90 = this.listGameshootFish.filter(d => d.pecent == 90)
        },
        creditCash (type) {
            $('.loading_show').show().fadeIn(10)
            this.axios.post(this.api_creditCash, 
            {
                type: type
            },
            {
                headers: {
                    Authorization: 'Bearer ' + this.token
                }
            }
            ).then(res => {
                this.orther = res
                $('#loading_show').css('display', 'none')
                this.showModal_cash = false
                this.showModal_noticash = true
            }).catch(e => {
                $('#loading_show').css('display', 'none')
                if (e.response.status === 405) {
                    this.$swal({
                        position: 'top-end',
                        icon: 'error',
                        title: e.response.data.message,
                        showConfirmButton: false,
                        timer: 3000
                    })
                    this.checkExp()
                    setTimeout(() => {
                        window.location.reload()
                    }, 2000);
                    
                }
            })
        },
        checkExp () {
            this.axios.post(this.api_checkExp, 
            {
                user_id: this.idUser
            },
            {
                headers: {
                    Authorization: 'Bearer ' + this.token
                }
            }
            ).then(res => {
                window.localStorage.setItem('status_time', res.data.data.status_time)
            })
        },
        showCompClick (event) {
            let percent = event.target.getAttribute('data_percent')
            let imggame = event.target.getAttribute('data_img')
            this.data_percent = percent
            this.imggame = imggame
            this.showModal_comp = true
        },
    }
}
</script>