<template>
    <Header_tool :class="{header_none: this.$route.name == 'Info_tool'}" />
    <router-view :key ='$route.params'/>
</template>

<script>
import "./assets/css/style.css"
import Header_tool from './components/Header.vue'
export default {
  name: 'App',
  data () {
        return {
        }
    },
    components: {
        Header_tool,
    },
    created () {
        
    },
    methods: {
        
    }
}
</script>

<style>
</style>
