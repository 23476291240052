import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'

import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

import "vue3-paginate-z/dist/styles.css";
import Paginate from "vue3-paginate-z";

import Vue3DraggableResizable from 'vue3-draggable-resizable'
import 'vue3-draggable-resizable/dist/Vue3DraggableResizable.css'

import bottomNavigationVue from "bottom-navigation-vue";
import "bottom-navigation-vue/dist/style.css";

import NumericInput from 'vue3-numeric-input';

const app = createApp(App)

var domain_web = 'https://adm.toolgambling.tech/api/'

app.config.globalProperties.link_web = 'https://adm.toolgambling.tech/'
app.config.globalProperties.api_login = domain_web + 'login'
app.config.globalProperties.api_logout = domain_web + 'logout'
app.config.globalProperties.api_register = domain_web + 'register'
app.config.globalProperties.api_infouser = domain_web + 'info-user'
app.config.globalProperties.api_creditCash = domain_web + 'credit-cash'
app.config.globalProperties.api_checkExp = domain_web + 'check-exp'
app.config.globalProperties.api_changeInfo = domain_web + 'change-name'

app.use(router)
app.use(VueAxios, axios)
app.use(Paginate)
app.use(VueSweetalert2)
app.use(bottomNavigationVue);
app.directive('numeric-input', NumericInput)
app.use(Vue3DraggableResizable)
app.mount('#app')
