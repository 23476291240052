<template>
    <div class="root_header_page">
        <header id="header" :class="'header has-sticky sticky-shrink ' + classHeader">
            <div class="container ner_responesize">
                <div :class="'menu_mobile ' + active">
                    <div class="info_mobile">
                        <ul class="btn_login_register_mobile" v-if="token == null">
                            <li v-if="token == null" class="btnlogin_header"><a @click="viewmodal_lg"><img src="../assets/images/login.png" alt=""></a></li>
                            <li v-if="token == null"><a @click="viewmodal_rg"><img src="../assets/images/register.png" alt=""></a></li>
                        </ul>
                        <ul class="info_user" v-else>
                            <li class="pad_header imgcss">
                                <a href="javascript:;">
                                    <img :src="link_web + picture" alt="" v-if="picture !== null"> 
                                    <img src="../assets/images/user.png" alt="" v-else> 
                                    {{ fullname }}
                                </a>
                                <ul>
                                    <li @click="logout">Đăng xuất</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <p @click="clickRemove">
                        <router-link v-if="$route.path != '/'" class="nav-item" to='/' title="">
                            <img src="../assets/images/logo.png" class="logo-mobile" alt="">
                        </router-link>
                        <a v-else class="nav-item" href='/' title="">
                            <img src="../assets/images/logo.png" class="logo-mobile" alt="">
                        </a>
                    </p>
                </div>
                <!-- modal login -->
                <div id="modal-example" class="modal-login">
                    <div class="overlay" v-if="showModal" @click="showModal = false"></div>
                    <div class="modal" v-if="showModal">
                        <div class="wp-modal-login">
                            <div class="header-modal">
                                <button class="close-modal_login" @click="showModal = false"><img src="../assets/images/close_md.png" alt=""></button>
                            </div>
                            <div class="content-modal_tab">
                                <div>
                                    <div class="logo_modal_login">
                                        <div class="img_pathner_md">
                                            <img src="../assets/images/logo.png" alt="">
                                        </div>
                                    </div>
                                    <Login_tool @showMd="showModalrg" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end modal login -->
                <!-- modal register -->
                <div id="modal-example" class="modal-login">
                    <div class="overlay" v-if="showModal_dk" @click="showModal_dk = false"></div>
                    <div class="modal" v-if="showModal_dk">
                        <div class="wp-modal-register">
                            <div class="header-modal">
                                <button class="close-modal_login" @click="showModal_dk = false"><img src="../assets/images/close_md.png" alt=""></button>
                            </div>
                            <div class="content-modal_tab">
                                <div>
                                    <div class="form_dangky">
                                        <div class="logo_modal_login">
                                            <div class="img_pathner_md">
                                                <img src="../assets/images/logo.png" alt="">
                                            </div>
                                        </div>
                                        <Register_tool @showMd="showModallg" @showMd_noti="showModal_noti"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end modal register -->
                <!-- modal noti register -->
                <div id="modal-example" class="modal-login modal-cash">
                    <div class="overlay" v-if="showModal_notiDk" @click="showModal_notiDk = false"></div>
                    <div class="modal" v-if="showModal_notiDk">
                        <div class="wp-modal-login">
                            <div class="header-modal">
                                <button class="close-modal_login" @click="showModal_notiDk = false"><img src="../assets/images/close_md.png" alt=""></button>
                            </div>
                            <div class="content-modal_tab">
                                <div>
                                    <div class="logo_modal_login">
                                        <div class="img_premiums" style="text-align: center;">
                                            <img src="../assets/images/tiger.png" alt="">
                                        </div>
                                    </div>
                                    <div class="form_dangky">
                                        <h2 class="noti_register">BẠN ĐÃ ĐĂNG KÝ THÀNH CÔNG, VUI LÒNG CHỜ CHÚNG TÔI XÁC MiNH THÔNG TIN KHI KHÁCH ĐĂNG KÝ THÀNH CÔNG GÓI VIP</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end modal noti register -->
            </div>
        </header>
        <CurvedBottomNavigation :options="options" v-model="selected" background-color='#07070A' foreground-color='' icon-color='#C01BA0'>
            <template #path="{ props }">
                <b>{{ props.path }}</b>
            </template>
        </CurvedBottomNavigation>
        <div id="loading_show" class="loading_show" style="position: fixed; left: 0; bottom: 0; top: 0%; right: 0%; text-align: center; background: rgba(0,0,0,.5); z-index: 999999999;">
            <div class="windows8"> 
                <div class="wBall" id="wBall_1"> 
                    <div class="wInnerBall"></div>
                </div>
                <div class="wBall" id="wBall_2">
                    <div class="wInnerBall"></div>
                </div>
                <div class="wBall" id="wBall_3">
                    <div class="wInnerBall"></div>
                </div> 
                <div class="wBall" id="wBall_4">
                    <div class="wInnerBall"></div> 
                </div>
                <div class="wBall" id="wBall_5"> 
                    <div class="wInnerBall"></div>
                </div>
            </div>
        </div>
        
        <div class="countdowTime">
            <Vue3DraggableResizable
                :initW="93"
                :initH="35"
                v-model:x="x"
                v-model:y="y"
                v-model:w="w"
                v-model:h="h"
                v-model:active="active"
                :draggable="true"
                :resizable="false"
                id="timer"
            >
            </Vue3DraggableResizable>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'

import Login_tool from '@/components/Login'
import Register_tool from '@/components/Register'
$( document ).ready(function() {
    document.getElementById('timer').innerHTML = "05" + ":" + "01"
    startTimer();

    function startTimer() {
        var presentTime = document.getElementById('timer').innerHTML;
        var timeArray = presentTime.split(/[:]+/)
        var m = timeArray[0];
        var s = checkSecond((timeArray[1] - 1))
        if(s==59){m=m-1}
        if(m<0){
            window.location.reload()
            return
        }
        document.getElementById('timer').innerHTML = m + ":" + s
        setTimeout(startTimer, 1000)
    }
    function checkSecond(sec) {
        if (sec < 10 && sec >= 0) {sec = "0" + sec}
        if (sec < 0) {sec = "59"}
        return sec
    }
});
export default {
    name: 'Header_tool',
    data () {
        return {
            active: '',
            selected: 3,
            classHeader: '',
            showModal: false,
            showModal_dk: false,
            showModal_notiDk: false,
            token: window.localStorage.getItem('token'),
            timeToken: window.localStorage.getItem('time_token'),
            fullname: '',
            picture: '',
            options: [
                { id: 1, icon: "falive_icon", title: "", path: { name: "Home_tool" } },
                { id: 2, icon: "fasupport", title: "", path: {name: "ShootFish_tool"} },
                { id: 3, icon: "fahome_icon", title: "", path: { name: "Home_tool" } },
                { id: 5, icon: "favideo", title: "", path: { name: "Home_tool" } },
                { id: 4, icon: "falive18_icon", title: "", path: {name: "Info_tool"} },
            ],
            resNull: []
        }
    },
    components: {
        Login_tool,
        Register_tool,
    },
    created () {
        if(this.token !== null){
            this.getListInfo()
            this.logoutMinutes()
        }
        window.addEventListener('scroll', this.handleScroll)
    },
    methods: {
        logout (e) {
            e.preventDefault()
            $('.loading_show').show().fadeIn(10)
            this.axios.post(this.api_logout).then(res => {
                if (res.data.success === true) {
                    $('#loading_show').css('display', 'none')
                    localStorage.clear();
                    this.$swal({
                        position: 'top-end',
                        icon: 'success',
                        title: res.data.message,
                        showConfirmButton: false,
                        timer: 4000
                    })
                    window.location.href = '/'
                }
            })
        },
        getListInfo () {
            this.axios.get(this.api_infouser, {
                headers: {
                    Authorization: 'Bearer ' + this.token
                }
            }).then((response) => {
                this.fullname = response.data.data.user.fullname
                this.picture = response.data.data.user.hinh_anh
            }).catch(e => {
                this.orther = e.response
                setTimeout(function() {
                    this.axios.post(this.api_logout).then(res => {
                        this.resNull = res
                        localStorage.clear();
                    })
                }.bind(this), 5000);
                setTimeout(function() {
                    window.location.href = '/'
                }, 2000);
            })
        },
        clickMenu () {
            this.active = 'active'
        },
        clickRemove () {
            this.active = ''
        },
        viewmodal_rg () {
            this.showModal_dk = true
            this.showModal = false
        },
        viewmodal_lg () {
            this.showModal = true
            this.showModal_dk = false
        },
        handleScroll () {
            if (window.scrollY > 100) {
                this.classHeader = 'active'
            }else{
                this.classHeader = ''
            }
        },
        logoutMinutes () {
            let today = new Date();
            let date = today.getFullYear() + '-' + (today.getMonth()+1) + '-' + today.getDate();
            let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
            let timestamp = date + ' ' + time;
            var d = new Date(timestamp.replace(/-/g, "/"));
            var d1 = new Date(this.timeToken.replace(/-/g, "/"));
            let timereset = Math.round(+d/1000) - Math.round(+d1/1000)
            let result = timereset / 60
            if(result > 120){
                this.axios.post(this.api_logout).then(res => {
                    if (res.data.success === true) {
                        localStorage.clear();
                        this.$swal({
                            position: 'top-end',
                            icon: 'success',
                            title: 'Kết thúc phiên đăng nhập',
                            showConfirmButton: false,
                            timer: 4000
                        })
                        setTimeout(() => {
                            window.location.href = '/'
                        }, 3000);
                    }
                })
            }
        },
        showModalrg () {
            this.showModal = false
            this.showModal_dk = true
        },
        showModallg () {
            this.showModal = true
            this.showModal_dk = false
        },
        showModal_noti () {
            this.showModal_notiDk = true
            this.showModal_dk = false
        },
    }
}
</script>
<style></style>
