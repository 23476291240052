<template>
    <div class="root_login">
        <form action="" method="post" id="frm_login" @submit="login">
                <p class="title_frm">Tên đăng nhập</p>
            <div class="input_modal">
                <input type="text" v-model="username_lg" placeholder="Tên đăng nhập">
                <img src="../assets/images/us.png" alt="" class="icon_input">
            </div>
                <p class="title_frm">Mật khẩu</p>
            <div class="input_modal">
                <input type="password" v-model="password_lg" placeholder="Vui lòng nhập mật khẩu">
                <img src="../assets/images/pw.png" alt="" class="icon_input">
            </div>
            <div class="btn_modal_login_tool">
                <div class="btn_login">
                    <button type="submit"><img src="../assets/images/dn.png" alt="" class=""></button>
                </div>
            </div>
            <p>Bạn chưa có tài khoản? <span @click="handleClick" class="register_text"><strong>Đăng ký</strong></span></p>
        </form>
    </div>
</template>

<script>
import $ from 'jquery'
export default {
    name: 'Login_tool',
    data () {
        return {
            username_lg: '',
            password_lg: '',
        }
    },
    components: {
        
    },
    created () {
    },
    methods: {
        login (e) {
            e.preventDefault()
            $('.loading_show').show().fadeIn(10)
            this.showModal = false
            this.showModals = false
            this.axios.post(this.api_login, {
                username: this.username_lg,
                password: this.password_lg,
            }).then(res => {
                // console.log(res);
                $('#loading_show').css('display', 'none')
                window.localStorage.setItem('token', res.data.data.token)
                window.localStorage.setItem('rank', res.data.data.user.rank)
                window.localStorage.setItem('numberus', res.data.data.user.id)
                window.localStorage.setItem('typeus', res.data.data.user.type)
                window.localStorage.setItem('status_time', res.data.data.user.status_time)
                window.localStorage.setItem('img', res.data.data.user.hinh_anh)
                window.localStorage.setItem('us', res.data.data.user.username)
                window.localStorage.setItem('type_cash', res.data.data.user.type_cash)
                // window.localStorage.setItem('offline', res.data.data.user.offline)
                // window.localStorage.setItem('first_password', res.data.data.user.first_password)
                // window.localStorage.setItem('last_login', res.data.data.user.last_login)
                let today = new Date();
                let date = today.getFullYear() + '-' + (today.getMonth()+1) + '-' + today.getDate();
                let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
                let timestamp = date + ' ' + time;
                window.localStorage.setItem('time_token', timestamp)
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 4000
                })
                this.username_lg = this.password_lg = ''
                window.location.reload()
            }).catch(e => {
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'error',
                    title: e.response.data.message,
                    showConfirmButton: false,
                    timer: 3000
                })
            })
        },
        handleClick () {
            this.$emit('showMd')
        }
    }
}
</script>
<style>
</style>
