import { createRouter, createWebHistory } from 'vue-router'

import Home_tool from '@/components/Home.vue'
import ShootFish_tool from '@/components/ShootFish.vue'
import Info_tool from '@/components/Info.vue'

const routes = [
    {
        path: '/',
        name: 'Home_tool',
        component: Home_tool
    },
    {
        path: '/ban-ca',
        name: 'ShootFish_tool',
        component: ShootFish_tool
    },
    {
        path: '/thong-tin',
        name: 'Info_tool',
        component: Info_tool
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes: routes,
    linkActiveClass: "active"
})

export default router